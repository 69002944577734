var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-reporte-ventas-totales"},[_c('v-container',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}},[_c('v-icon',{attrs:{"slot":"divider"},slot:"divider"},[_vm._v("forward")])],1)],1),_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"justify":'center',"align":'center'}},[_c('v-col',{attrs:{"sm":"12"}},[_c('base-material-card',{staticClass:"elevation-1 px-5 py-3",attrs:{"color":"primary","icon":"library_books","title":"Ventas Totales"}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"4","md":"4","lg":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.sucursales,"hide-no-data":true,"hide-selected":true,"item-text":"nombre","item-value":"_id","label":"Sucursal"},model:{value:(_vm.model.id_sucursal),callback:function ($$v) {_vm.$set(_vm.model, "id_sucursal", $$v)},expression:"model.id_sucursal"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"offset-x":"","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha De","color":"secondary","prepend-icon":"event","rules":[_vm.rules.required, _vm.valida_fecha]},on:{"blur":function($event){_vm.model.fecha_de = _vm.parse_date(_vm.model.fecha_de)}},model:{value:(_vm.model.fecha_de),callback:function ($$v) {_vm.$set(_vm.model, "fecha_de", $$v)},expression:"model.fecha_de"}},on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"secondary","scrollable":"","locale":"es-mx"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.model.fecha_de),callback:function ($$v) {_vm.$set(_vm.model, "fecha_de", $$v)},expression:"model.fecha_de"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"offset-x":"","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha A","color":"secondary","prepend-icon":"event","rules":[_vm.rules.required, _vm.valida_fecha]},on:{"blur":function($event){_vm.model.fecha_a = _vm.parse_date(_vm.model.fecha_a)}},model:{value:(_vm.model.fecha_a),callback:function ($$v) {_vm.$set(_vm.model, "fecha_a", $$v)},expression:"model.fecha_a"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"secondary","scrollable":"","locale":"es-mx"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.model.fecha_a),callback:function ($$v) {_vm.$set(_vm.model, "fecha_a", $$v)},expression:"model.fecha_a"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('v-menu',{ref:"menu3",attrs:{"offset-x":"","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hora De","color":"secondary","prepend-icon":"event","rules":[_vm.valida_time],"clearable":"","maxlength":"5"},model:{value:(_vm.model.hora_de),callback:function ($$v) {_vm.$set(_vm.model, "hora_de", $$v)},expression:"model.hora_de"}},on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-time-picker',{attrs:{"color":"secondary","scrollable":"","locale":"es-mx"},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.model.hora_de),callback:function ($$v) {_vm.$set(_vm.model, "hora_de", $$v)},expression:"model.hora_de"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){_vm.menu3 = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('v-menu',{ref:"menu4",attrs:{"offset-x":"","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hora A","color":"secondary","prepend-icon":"event","rules":[_vm.valida_time],"clearable":"","maxlength":"5"},model:{value:(_vm.model.hora_a),callback:function ($$v) {_vm.$set(_vm.model, "hora_a", $$v)},expression:"model.hora_a"}},on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-time-picker',{attrs:{"color":"secondary","scrollable":"","locale":"es-mx"},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.model.hora_a),callback:function ($$v) {_vm.$set(_vm.model, "hora_a", $$v)},expression:"model.hora_a"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){_vm.menu4 = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"4","md":"4","lg":"4"}},[_c('v-switch',{attrs:{"label":"Incluir canceladas","true-value":"1","false-value":"0"},model:{value:(_vm.model.canceladas),callback:function ($$v) {_vm.$set(_vm.model, "canceladas", $$v)},expression:"model.canceladas"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{nativeOn:{"click":function($event){_vm.model = _vm.clean_model()}}},[_c('v-icon',[_vm._v("cancel")]),_vm._v(" Limpiar Filtros ")],1),_c('v-btn',{attrs:{"color":"success"},nativeOn:{"click":function($event){return _vm.generar_reporte()}}},[_c('v-icon',[_vm._v("done")]),_vm._v(" "+_vm._s("Generar Reporte")+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }